<template>
  <div class="md:tw-mt-2 tw-mt-16 tw-p-8 md:tw-p-0">
    <div class="md:tw-hidden" @click="$emit('toggle')">
      <vue-feather type="x"></vue-feather>
    </div>
    <div  v-if="!comments.length" class="tw-flex tw-justify-center tw-items-center">
      <small>No Interview Comment</small>
    </div>

  <div v-else class="comments tw-mt-4 ">
    <h3>Comments</h3>
    <div class="tw-my-4">
      <v-select solo hide-details label="All Interviewers" :items="getAllAdmin" v-model="user" :item-value="getAllAdmin.admin_full_name">

      </v-select>
    </div>
    <div class="scroll">
    <div class="tw-p-4 md:tw-p-0" v-for="(comment,i) in getCommentBasedOnUser" :key="i">
      <v-avatar class="avatar" size="30">{{comment.admin_full_name.charAt(0)+''+ comment.admin_full_name.charAt(1)}}</v-avatar>
      <h5>
        {{comment.message}}
      </h5>
      <span>{{comment.admin_full_name}}</span>

    </div>
    </div>

  </div>
  </div>

</template>

<script>
import {getAllAdminInterviewComment} from "@/services/api/APIService";

export default {
  name: "AllAdminInterviewComment",
  data(){
    return{
      comments:[],
      user:''

    }
  },
  created() {
    this.getInterviewComment()
  },
  computed:{
    getCommentBasedOnUser(){
        if(this.user && this.user !== 'All Comment'){
          return this.comments.filter(e=>e.admin_full_name===this.user)
        }
        else {
          return this.comments
        }
      },
    getAllAdmin(){
        let admins = []
        admins= this.comments.map(e => e.admin_full_name)
        return admins.concat('All Comment')
      }

  },
  methods:{
    getInterviewComment(){
      getAllAdminInterviewComment(this.$route.query.interview, this.$route.query.email)
      .then(res=>{
        this.comments = res.data
      })
      .catch(err=>{
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>
.comments h3{
  ont-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #4F4F4F;
}
.comments h5{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #475661;
  margin-top: 5px;
  margin-bottom: 5px;
}
.comments span{
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  color: #475661;

}
.avatar{
  background: #EEF8FF;
  border: 1.5px solid #FFFFFF;
  box-sizing: border-box;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 175% */

  display: flex;
  align-items: center;
  justify-content: center;
  color: #008EEF;
  text-transform: uppercase;
  margin-top: 15px;
}
.scroll{
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  /*width: ;*/

}
.scroll::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 8px;
}

.scroll::-webkit-scrollbar-thumb {
  background: rgba(43, 28, 28, 0.5);
  max-height: 10px !important;
  border-radius: 6px;
}

</style>
