<template>
<diV>
  <div>
    <h4 class="name">Interview with {{responseData.applicant_data.applicant}}</h4>
  </div>
  <div class="tw-flex report tw-my-8 tw-flex-col md:tw-flex-row tw-justify-between">
    <div class="tw-flex">
      <h3 class="tw-mr-10"> {{$route.query.rating==='no rating'?'No rating':$route.query.rating +'%'}}</h3>
      <div>
        <div v-for="(progress, i) in responseData.insights" :key="i" class="d-flex tw-mb-3 align-center">
          <span style="margin-bottom: 0 !important;">{{progress.number}}</span><v-progress-linear height="6px" class="progress tw-ml-4" :value="progress.percent" color="#8F47F4"/>
        </div>
      </div>

    </div>
    <div>
      <div class="tw-mt-8 md:tw-mt-0">
        <span>INVITED BY</span>
        <div class="tw-flex">
          <v-avatar class="avatar" size="30">{{responseData.evaluations[0].invited_by.charAt(0)+''+ responseData.evaluations[0].invited_by.charAt(1)}}</v-avatar>
          <h2>{{responseData.evaluations[0].invited_by}}</h2>

        </div>
      </div>
      <div class="tw-mt-4">
        <span>DATE</span>
        <h2><DateFormatter :date="responseData.evaluations[0].date_created"/> </h2>
      </div>
    </div>
    <div class="tw-mt-8 md:tw-mt-0">
      <span>INTERVIEWERS</span>
      <div class="tw-flex" v-for="(interviewer ,i) in getInterviewers" :key="i">
        <v-avatar class="avatar" size="30">{{interviewer.charAt(0)+''+ interviewer.charAt(1)}}</v-avatar>
        <h2>{{interviewer}}</h2>

      </div>
    </div>
  </div>
</diV>
</template>

<script>
import DateFormatter from "@/components/dashboard/reuseables/DateFormatter";
export default {
name: "InterviewReportApplicantSummary",
  components: {DateFormatter},
  props:{
    responseData:[Object]
  },
  computed:{
      getInterviewers(){
        let interviewers = []
        interviewers= this.responseData.evaluations.map(e => e.admin_full_name)
        console.log(interviewers)
        return interviewers
      }
  }
}
</script>

<style scoped>
.name{
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.02em;
  color: #142E70;
  text-transform: capitalize !important;
}
.report span{
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #4F4F4F;
  margin-bottom: 10px;
  /*margin-left: 4px;*/
}
.report h2{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #4F4F4F;
}
.avatar{
  background: #EEF8FF;
  border: 1.5px solid #FFFFFF;
  box-sizing: border-box;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 175% */
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #008EEF;
  margin-right: 5px;
}
.progress{
  width: 156px;
  background: #EFE4FE;
  border-radius: 300px;
}

</style>
