<template>
  <div>
    <div  class=" lg:tw-w-10/12 md:tw-p-0 tw-p-8 " style="margin: 120px auto 0;">
      <v-btn text @click="$router.go(-1)">
        <vue-feather style="width: 25px;  color: #9CABB5" type="arrow-left"></vue-feather>
        <span class="back text-capitalize">Back</span>
      </v-btn>
      <v-row class="tw-flex-between" no-gutters>
        <v-col md="7" sm="12">
          <InterviewReportApplicantSummary :response-data="responseData"/>
          <div>
            <div class="tw-flex tw-my-6">
              <div :class="component==='interviewers'?'active-btn':'round-btn'"
                   @click="component='interviewers';reportIndex=0">
                Interviewers
              </div>
              <div :class="component==='criteria'?'active-btn':'round-btn'" @click="component='criteria'">
                Criteria
              </div>
            </div>
            <component v-bind:is="componentToDisplay()" :criteria-based-report="criteriaReport" :criteria-index="reportIndex"
                       :criteria-report="reports" @decrease="decreaseIndex"
                       @increment="incrementIndex" @increment-criteria="incrementIndexForCriteria"></component>
            <div class="tw-flex md:tw-hidden tw-justify-end" @click="$emit('toggle',true)">
              <div class="comment-mobile">
                <vue-feather style="margin-right: 5px" type="message-square"></vue-feather>
                <span>View Comments</span>
              </div>
            </div>
          </div>
        </v-col>
        <v-col class="tw-hidden lg:tw-block" md="2" sm="0"></v-col>
        <v-col class="tw-hidden  lg:tw-block" md="3" sm="0">
          <AllAdminInterviewComment/>
        </v-col>

      </v-row>
    </div>
  </div>


</template>

<script>
import InterviewersBasedReport from "@/components/candidateProfile/interviewReport/InterviewersBasedReport";
import CriteriaBasedReport from "@/components/candidateProfile/interviewReport/CriteriaBasedReport";
import {getInterviewReportByCriteria} from "@/services/api/APIService";
import InterviewReportApplicantSummary
  from "@/components/candidateProfile/interviewReport/InterviewReportApplicantSummary";
import AllAdminInterviewComment from "@/components/candidateProfile/interviewReport/AllAdminInterviewComment";
import Loader from "@/components/UIComponents/reusablesIcon/Loader";

export default {
  name: "InterviewReportDesktopView",
  components: {
    Loader,
    AllAdminInterviewComment,
    InterviewReportApplicantSummary, InterviewersBasedReport,CriteriaBasedReport
  },
  props:{
    reports:[Array],
    responseData:[Array,Object]
  },
  data() {
    return {
      reportIndex: 0,
      component: 'interviewers',
      criteriaReport: [],
      loading: false
    }
  },
  created() {
    this.getReportBasedOnCriteria()
  },
  methods: {
    incrementIndex() {
      if (this.reportIndex < this.reports.length - 1) {
        this.reportIndex++
      }
    },
    incrementIndexForCriteria() {
      if (this.reportIndex < this.criteriaReport.length - 1) {
        this.reportIndex++
      }
    },
    decreaseIndex() {
      if (this.reportIndex > 0) {
        this.reportIndex--
      }
    },
    getReportBasedOnCriteria() {
      getInterviewReportByCriteria(this.$route.query.interview, this.$route.query.email)
          .then(res => {
            let data = []
            data = res.data.map(e => {
              return {
                criteria: e.criterion,
                score: e.admins
              }
            })
            this.criteriaReport = data
          })
          .catch(err => {
            console.log(err)
          })


    },
    componentToDisplay() {
      if (this.component === 'criteria') {
        return CriteriaBasedReport
      } else {
        return InterviewersBasedReport
      }
    }
  }
}
</script>

<style scoped>
.back {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #4F4F4F;
}

.active-btn {
  background: #F6FCFF;
  border: 1px solid #008EEF;
  box-sizing: border-box;
  border-radius: 120px;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
}

.round-btn {
  background: #FFFFFF;
  border: 1px solid #C5E7FF;
  box-sizing: border-box;
  border-radius: 120px;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;


}

.comment-mobile {
  background: #008EEF;
  border-radius: 300px;
  padding: 10px;
  width: 176px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #FFFFFF;
  display: flex;


}


</style>
