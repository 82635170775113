<template>
<div>
  <Header>
    <template #right-header-component>
      <ProfileDropDown/>
    </template>
  </Header>
  <div class="tw-mt-12" v-if="loading">
    <Loader :loading="loading"/>
  </div>
  <keep-alive v-else-if="!loading && reports.length">
  <component :response-data="responseData" :reports="reports" @toggle="toggleDisplay" v-bind:is="displayComponent()"></component>
  </keep-alive>
  <div v-else class="tw-flex tw-justify-center tw-items-center"  style="margin: 120px auto 0;">
    <span>No Interview Report</span>
  </div>
</div>
</template>

<script>
import InterviewReportDesktopView from "@/views/interview/InterviewReportDesktopView";
import AllAdminInterviewComment from "@/components/candidateProfile/interviewReport/AllAdminInterviewComment";
import {getInterviewReportByAdmin} from "@/services/api/APIService";
import Loader from "@/components/UIComponents/reusablesIcon/Loader";
import ProfileDropDown from "@/components/dashboard/reuseables/ProfileDropDown";
import Header from "@/components/onboarding/reuseables/Header";
export default {
name: "InterviewReportView",
  components:{Header, ProfileDropDown, Loader, InterviewReportDesktopView,AllAdminInterviewComment},
  data(){
    return{
      isMobile:false,
      responseData:{},
      reports:[],
      loading:false
    }
  },
  created() {
  this.getReportBasedOnInterviewers()
  },
  methods:{
  toggleDisplay(status){
    this.isMobile = status
  },
    displayComponent(){
        if(this.isMobile){
          return AllAdminInterviewComment
        }
        else {
          return  InterviewReportDesktopView
        }
    },
    getReportBasedOnInterviewers() {
      this.loading = true
      getInterviewReportByAdmin(this.$route.query.interview, this.$route.query.email)
          .then(res => {
            console.log(res.data)
            let data = []
            data = res.data.evaluations.map(e => {
              return {
                criteria: e.admin_full_name,
                score: e.evaluation_score
              }
            })
            this.reports = data
            this.responseData = res.data
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => this.loading = false)


    },

  }
}
</script>

<style scoped>

</style>
