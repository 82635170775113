<template>
<div>{{processedDateAndTime}}</div>
</template>

<script>
export default {
  name: "DateFormatter",
  props:{
    date : [String]
},
  computed: {
    processedDateAndTime() {
      let dateAndTime = this.date.split(".")[0];
      let date = dateAndTime.split("T")[0];
      let time = dateAndTime.split("T")[1];
      let dateObj = new Date(date);
      let hour = time.split(":")[0];
      let minutes = time.split(":")[1];
      return (
          dateObj.toDateString() +
          ", " +
          hour +
          ":" +
          minutes +
          (hour / 12 < 1 || hour === 12 ? " AM" : " PM")
      );
    },
  },
}
</script>

<style scoped>

</style>