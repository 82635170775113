<template>
  <div class="tw-mt-10 ">
    <div class="tw-flex tw-justify-between tw-items-center">
      <v-btn icon @click="$emit('decrease')">
      <vue-feather type="chevron-left"></vue-feather>
      </v-btn>
      <h3 class="heading">{{ criteriaBasedReport[criteriaIndex].criteria }}</h3>
      <v-btn icon  @click="$emit('increment-criteria')">
        <vue-feather type="chevron-right" />
      </v-btn>


    </div>
    <div class="tw-flex tw-my-4 tw-justify-between tw-items-center">
      <h3 class="sub-heading">Criteria</h3>
      <h3 class="sub-heading">Score</h3>
    </div>
    <v-divider></v-divider>
    <div v-for="(index,data,i) in criteriaBasedReport[criteriaIndex].score" :key="i">
      <div class="tw-flex tw-my-3 tw-justify-between tw-items-center">
        <h3 class="data">{{data}}</h3>
        <h3 class="data">{{index}}</h3>
      </div>
    </div>
    <div class="tw-flex tw-my-4 tw-justify-between tw-items-center">
      <h3 class="sub-heading">Total</h3>
      <h3 class="sub-heading">{{getTotalScore}}</h3>
    </div>
  </div>

</template>

<script>
export default {
name: "CriteriaBasedReport",
  props:{
    criteriaBasedReport:[Array],
    criteriaIndex:[Number]
  },
  computed:{
    getTotalScore(){
      let scores = Object.values(this.criteriaBasedReport[this.criteriaIndex].score)
      console.log(scores)
      return scores.reduce((a,b)=>(+a)+(+b))

    }
  }
}
</script>

<style scoped>
.heading{
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #4F4F4F;
}
.sub-heading{
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #1E323F;
}
.data{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #4F4F4F;
  margin-bottom: 10px;
}

</style>
